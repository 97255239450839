/* src/components/Contact.css */
.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;
}

.contact-container h1 {
    text-align: center;
    margin-bottom: 30px;
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-info {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
}

.contact-info p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.social-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.social-links li {
    margin-bottom: 15px;
}

.social-links a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #ddd;
}

.social-links i {
    margin-right: 10px;
    width: 20px;
}

.email-contact {
    font-size: 18px;
    margin-top: 20px;
}

.email-contact a {
    color: #fff;
    text-decoration: underline;
}

.contact-images {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.contact-images img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
}

.contact-images img:hover {
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .social-links {
        flex-direction: column;
    }
    
    .contact-images {
        grid-template-columns: 1fr;
    }
}