/* src/components/NavBar.css */
.navbar {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
    padding: 15px 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: background 0.3s ease;
}

.navbar:hover {
    background: rgba(0, 0, 0, 0.95);
}

.navbar-logo {
    width: 170px;
    height: 85px;
    transition: transform 0.3s ease;
}

.navbar-logo:hover {
    transform: scale(1.05);
}

.navbar-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navbar-toggle {
    display: none;
    font-size: 28px;
    cursor: pointer;
    color: white;
    background: none;
    border: none;
    transition: color 0.3s ease;
}

.navbar-toggle:hover {
    color: #ffd700;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
}

.navbar-links li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.navbar-links li a:hover {
    color: #ffd700;
    background: rgba(255, 255, 255, 0.1);
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .navbar {
        padding: 10px 20px;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.95);
        padding: 20px 0;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .navbar-links.open {
        display: flex;
    }

    .navbar-links li {
        margin: 10px 0;
    }

    .navbar-links li a {
        display: block;
        padding: 15px 20px;
    }
}

/* Animation for mobile menu */
@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.navbar-links.open {
    animation: slideDown 0.3s ease-out forwards;
}
