.home-container {
    padding: 60px 20px 20px;
    background-color: #121212;
    color: #ffffff;
    text-align: center;
}

.hero-section {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    height: 70vh;
    overflow: hidden;
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
    transition: transform 0.3s ease;
}

.hero-section:hover .hero-image {
    transform: scale(1.05);
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    text-align: center;
}

.hero-text h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.content-section {
    margin-top: 40px;
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
}

.content-section h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #ffd700;
}

.event-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.event-card {
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #333, #444);
    padding: 15px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.event-icon {
    font-size: 2rem;
    margin-right: 15px;
    color: #ffd700;
}

.event-details h3 {
    font-size: 1.2rem;
    margin: 0;
}

.event-details p {
    font-size: 1rem;
    margin: 5px 0 0;
}

.past-event {
    background: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    color: white;
}

.past-event:hover {
    background: rgba(0, 0, 0, 0.8);
}

.more-events {
    margin-top: 20px;
    font-style: italic;
    color: #bbbbbb;
}

/* Responsive */
@media (max-width: 768px) {
    .hero-section {
        height: 50vh;
    }

    .hero-text h1 {
        font-size: 2rem;
    }

    .event-card {
        flex-direction: column;
        text-align: center;
    }

    .event-icon {
        margin-bottom: 10px;
    }
}
