/* src/components/Merchandise.css */
.merchandise-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px 40px;
    background-color: #121212;
    color: #ffffff;
}

.merchandise-container h1 {
    text-align: center;
    margin-bottom: 40px;
    color: #fefefe;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.merchandise-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
}

.merchandise-item {
    background: linear-gradient(to bottom, #222, #333);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.merchandise-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.merchandise-item a {
    text-decoration: none;
    color: #ffffff;
    display: block;
}

.merchandise-item img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: opacity 0.3s ease;
}

.merchandise-item:hover img {
    opacity: 0.8;
}

.merchandise-item p {
    padding: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 1.1rem;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
}

@media (max-width: 768px) {
    .merchandise-container {
        padding: 60px 20px 30px;
    }

    .merchandise-container h1 {
        font-size: 2rem;
        margin-bottom: 30px;
    }

    .merchandise-grid {
        gap: 20px;
    }

    .merchandise-item img {
        height: 200px;
    }

    .merchandise-item p {
        font-size: 1rem;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .merchandise-container {
        padding: 50px 15px 25px;
    }

    .merchandise-container h1 {
        font-size: 1.8rem;
        margin-bottom: 25px;
    }

    .merchandise-grid {
        grid-template-columns: 1fr;
    }

    .merchandise-item img {
        height: 180px;
    }
}