/* src/components/About.css */
.about-container {
    padding: 80px 20px 40px;
    background-color: #121212;
    color: #ffffff;
    text-align: center;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: auto;
}

.about-photo {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-photo:hover {
    transform: scale(1.02);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.about-text {
    text-align: left;
    background: rgba(255, 255, 255, 0.05);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffd700;
}

.about-text p, .about-text ul {
    margin-bottom: 20px;
    font-size: 1.1rem;
    line-height: 1.6;
}

.about-text ul {
    list-style-type: none;
    padding: 0;
}

.about-text ul li {
    background: linear-gradient(to right, #333, #444);
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-text ul li:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.spotify-embed {
    margin-top: 40px;
    max-width: 800px;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .about-container {
        padding: 60px 20px 30px;
    }

    .about-text h2 {
        font-size: 1.8rem;
    }

    .about-text p, .about-text ul {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .about-container {
        padding: 50px 15px 25px;
    }

    .about-text h2 {
        font-size: 1.6rem;
    }

    .about-text p, .about-text ul {
        font-size: 0.9rem;
    }

    .about-text {
        padding: 20px;
    }
}