/* src/components/CornerstoneFestival.css */
.cornerstone-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;
}

.cornerstone-container h1 {
    text-align: center;
    margin-bottom: 30px;
}

.festival-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.poster-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 40px;
}

.festival-poster {
    width: 100%;
    height: auto;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}

.festival-info {
    width: 100%;
    max-width: 800px;
}

.festival-info h2 {
    margin-top: 30px;
    margin-bottom: 15px;
    color: #ddd;
}

.festival-info p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.festival-info ul {
    list-style-type: none;
    padding: 0;
}

.festival-info ul li {
    font-size: 18px;
    margin-bottom: 10px;
}

.festival-info a {
    color: #4CAF50; /* Change this to match your color scheme */
    text-decoration: none;
    transition: color 0.3s ease;
}

.festival-info a:hover {
    color: #45a049; /* Slightly darker shade for hover effect */
    text-decoration: underline;
}

/* Updated styles for the image gallery */
.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.gallery-image {
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: contain;
    background-color: #000;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .festival-content {
        flex-direction: column;
    }
    
    .poster-container {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .image-gallery {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media (max-width: 480px) {
    .image-gallery {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}