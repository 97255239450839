/* Gallery Container */
.gallery-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #000;
    color: white;
}

/* Gig Section */
.gig-section {
    margin-bottom: 40px;
}

/* Gig Title */
.gig-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
    border-bottom: 2px solid #fff;
    display: inline-block;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.image-grid {
    column-count: 4; /* Creates a multi-column masonry effect */
    column-gap: 10px;
    width: 100%;
}

.grid-image {
    width: 100%;
    margin-bottom: 10px; /* Prevents overlap */
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    display: block; /* Ensures images fit within columns */
}
/* Dynamically assign row spans to create a masonry effect */
.grid-image:nth-child(2n) { grid-row: span 25; }
.grid-image:nth-child(3n) { grid-row: span 30; }
.grid-image:nth-child(4n) { grid-row: span 20; }
.grid-image:nth-child(5n) { grid-row: span 35; }


/* Hover Effect */
.grid-image:hover {
    transform: scale(1.05);
}

/* Fullscreen Image Modal */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .image-grid {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
}

@media (max-width: 768px) {
    .image-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}

@media (max-width: 480px) {
    .image-grid {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
}
